import { enums } from '@/core/enums'

export default [
  {
    path: 'dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName "dashboard" */ '@/views/Dashboard.vue')
  },

  {
    path: 'orders',
    name: 'Orders',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName "orders" */ '@/views/Orders.vue')
  },

  {
    path: 'orders/:orderId',
    name: 'Order',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName "orders-detail" */ '@/views/Order.vue')
  },

  {
    path: 'products',
    name: 'Products',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName "products" */ '@/views/Products.vue')
  },

  {
    path: 'products/:productId',
    name: 'Product',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName "products" */ '@/views/Product.vue')
  },

  {
    path: 'inventory',
    name: 'Inventory',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "inventory-page" */ '@/views/Inventory.vue')
  },

  {
    path: 'finance',
    name: 'Finance',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "finance" */ '@/views/Finance.vue')
  },

  {
    path: 'email-editor',
    name: 'EmailEditor',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "email-editor" */ '@/views/EmailEditor.vue')
  },

  {
    path: '/', redirect: 'login'
  }
]
