import { enums } from '@/core/enums'

export default [

  {
    path: 'users',
    name: 'AdminUsers',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "admin-users" */ '@/views/admin-users/Users.vue')
  },

  {
    path: 'users/admin/:id',
    name: 'AdminUserEdit',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "admin-user-edit" */ '@/views/admin-users/AdminUser.vue')
  },

  {
    path: 'users/advertiser/:id',
    name: 'AdvertiserUserEdit',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "admin-user-edit" */ '@/views/admin-users/AdvertiserUser.vue')
  },

  {
    path: 'users-create',
    name: 'AdminUserCreate',
    meta: {
      requiresAuth: true,
      access: [enums.ROLES.ADMIN]
    },
    component: () => import(/* webpackChunkName "admin-user-create" */ '@/views/admin-users/CreateUser.vue')
  }
]
