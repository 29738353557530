export default {
  state: {
    content: [
      'This is where the help text for each screen will appear'
    ],
    visible: false
  },

  mutations: {

    showTooltip (state, data) {
      const { message } = data

      state.content = message
      state.visible = true
    },

    hideTooltip (state) {
      state.content = null
      state.visible = false
    }
  },

  actions: {
    showTooltip ({ commit }, data) {
      commit('showTooltip', data)
    },

    hideTooltip ({ commit }) {
      commit('hideTooltip')
    }
  }
}
