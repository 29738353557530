import $axios from '@/core/api.config'

class AuthService {
  // authenticate user
  login ({ email, password }) {
    return $axios.post('/authenticate', { email, password })
  }

  // request to reset password
  requestResetPassword ({ email, url }) {
    return $axios.post('/users/request-reset-password', { email, url })
  }

  // reset password
  resetPassword ({ email, token, password }) {
    return $axios.post('/users/reset-password', { email, token, password })
  }
}

export default new AuthService()
