import Vue from 'vue'
import VueRouter from 'vue-router'

import { routes } from './routes'
import { beforeEach } from './guards'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'text-grey border-indigo hover:border-indigo',
  routes
})

router.beforeEach(beforeEach)

export default router
