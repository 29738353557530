import moment from 'moment'

export const formatNumber = (value, digits) => {
  return new Intl.NumberFormat(undefined, { minimumFractionDigits: digits }).format(value)
}

export const formatDate = (value, options) => {
  return moment(String(value)).format('DD-MM-YYYY')
}

export const formatTime = (value, options) => {
  return moment(String(value)).format('HH:mm')
}

export const preventBodyScroll = (value) => {
  const bodyClasses = document.getElementsByTagName('html')[0].classList

  if (value) {
    bodyClasses.add('prevent-body-scroll')
  } else {
    bodyClasses.remove('prevent-body-scroll')
  }
}

export const getRandomUpperCase = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 65)
}

export const getRandomLowerCase = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
}

export const getRandomNumber = () => {
  return String.fromCharCode(Math.floor(Math.random() * 10) + 48)
}

export const getRandomSymbol = () => {
  const symbol = '!@#$%^&*(){}[]=<>/,.|~?'
  return symbol[Math.floor(Math.random() * symbol.length)]
}

export const generatePassword = (upper = true, lower = true, number = true, symbol = true, length = 8) => {
  let generatedPassword = ''
  const randomFunc = {
    upper: getRandomUpperCase,
    lower: getRandomLowerCase,
    number: getRandomNumber,
    symbol: getRandomSymbol
  }

  const typesCount = upper + lower + number + symbol

  const typesArr = [{ upper }, { lower }, { number }, { symbol }].filter(item => Object.values(item)[0])

  if (typesCount === 0) {
    return ''
  }

  for (let i = 0; i < length; i += typesCount) {
    typesArr.forEach(type => {
      const funcName = Object.keys(type)[0]
      generatedPassword += randomFunc[funcName]()
    })
  }

  const finalPassword = generatedPassword.slice(0, length)

  return finalPassword
}

export const getChaining = (value, path, defaultValue) => {
  return String(path).split('.').reduce((acc, v) => {
    try {
      acc = acc[v]
    } catch (e) {
      return defaultValue
    }
    return acc
  }, value)
}

// clear empty string form params
export const generateRequestParams = (filters = {}) => {
  for (const key of Object.keys(filters)) {
    if (filters[key] === '') {
      delete filters[key]
    }
  }
  return filters
}
