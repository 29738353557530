import Vuex from 'vuex'
import Vue from 'vue'

/* import modules */
import loading from './loading.store'
import auth from './auth.store'
import notification from './notification.store'
import slideTooltip from './slide-tooltip.store'
import user from './user.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    notification,
    slideTooltip,
    loading,
    user
  }
})
