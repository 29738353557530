const ENUMS = {
  ROLES: {
    ADMIN: 'admin',
    ADVERTISER: 'advertiser'
  }
}

Object.freeze(ENUMS)
Object.freeze(ENUMS.ROLES)

export const enums = ENUMS
