export default [
  {
    path: 'login',
    name: 'Login',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName "login" */ '@/views/auth/Login.vue')
  },

  {
    path: 'reset-password',
    name: 'ResetPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName "resetPassword" */ '@/views/auth/ResetPassword.vue')
  },

  {
    path: '/', redirect: 'login'
  }
]
