import CookiesService from '@/core/cookies.service'

export default {
  state: {
    userData: null
  },

  mutations: {
    userData (state, data) {
      state.userData = data
    }
  },

  actions: {
    getUserFromToken ({ commit }) {
      const token = CookiesService.getItem('token')
      const userData = JSON.parse(atob(token.split('.')[1]))

      commit('userData', { name: userData.name, email: userData.email, role: userData.role })
      return userData
    }
  }
}
