export default {
  state: {
    loading: false
  },

  mutations: {
    loading (state, value) {
      state.loading = value
    }
  },

  actions: {
    loading ({ commit }, value) {
      commit('loading', value)
    }
  }
}
