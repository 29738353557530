import Vue from 'vue'

class CookiesService {
  setItem (key, data, expires) {
    Vue.$cookies.set(key, data, expires)
  }

  getItem (key) {
    return Vue.$cookies.isKey(key) ? Vue.$cookies.get(key) : null
  }

  remove (key) {
    Vue.$cookies.remove(key)
  }
}

export default new CookiesService()
