import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('required', {
  ...rules.required,
  message: '{_field_} is required'
})

extend('validate_pass', {
  params: ['otherValue', 'maxDifference'],
  validate: (value) => {
    const strongRegex = new RegExp(/^(?=.{8,}$)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).*$/)
    return strongRegex.test(value)
  },
  message: 'Minimum 8 characters, at least one lower case characters, at least one uppercase character, at least one number and at least one symbol'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('image_upload', {
  params: ['otherValue', 'maxDifference'],
  validate: (value) => {
    const size = parseFloat((value / 1024) / 1024).toFixed(2)
    return size <= 10
  },
  message: 'Image size can not be more than 10 Mb.'
})

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
