import store from '@/store'

export const beforeEach = async (to, from, next) => {
  const isLoggedIn = !!store.state.auth.token
  const isAuthNeeded = to.matched.some(route => route.meta.requiresAuth)
  const isAuthLayout = to.matched.some(route => route.name === 'Auth')

  // check for auth access
  if (isAuthNeeded && !isLoggedIn) {
    store.dispatch('showNotification', {
      message: 'Please, sign in to have access to this page!',
      type: 'error'
    })
    const test = JSON.stringify({ routeName: to.name, params: to.params })
    return next({ name: 'Login', query: { redirectedFrom: test } })
  } else if (isLoggedIn && !store.state.user.userData) {
    // always set the user data if we have token
    store.dispatch('getUserFromToken')
  }

  const isAccessNeeded = to.meta.access && to.meta.access.length
  const isAccessAllowed = to.meta.access ? to.meta.access.includes(store.state.user.userData.role) : false

  if (isAccessNeeded && isLoggedIn && !isAccessAllowed) {
    store.dispatch('showNotification', {
      message: 'You don\'t have access to this page!',
      type: 'error'
    })
    return next({ name: 'Login' })
  }

  if (!isAuthLayout && to.name !== 'Playground') store.dispatch('loading', true)

  // Playground route only in dev mode
  if (process.env.NODE_ENV === 'production' && to.name === 'Playground') return next({ name: 'Login' })

  next()
}
