/**
 * @file api-config provides promise-based HTTP abstraction with extended error
 */

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

const $axios = axios.create({
  // setup root url for all http requests
  baseURL: process.env.VUE_APP_API,
  // customized XMLHttpRequest wrapper library
  headers: { 'Content-Type': 'application/json' }
})

// global request interceptor
$axios.interceptors.request.use((config) => {
  // intercept url for auth
  if (store.state.auth.token) {
    config.headers.Authorization = `Bearer ${store.state.auth.token}`
  }

  return config
})

// global response interceptor
$axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    store.dispatch('loading', false)

    return Promise.reject(error.response)
  }
)

Vue.prototype.$axios = $axios

export default $axios
