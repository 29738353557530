import CookiesService from '@/core/cookies.service'
import AuthService from '@/services/auth.service'

export default {
  state: {
    token: CookiesService.getItem('token') || null
  },

  mutations: {
    setToken (state, token) {
      state.token = token
    }
  },

  actions: {
    login ({ commit, dispatch }, data) {
      return AuthService.login(data)
        .then(({ token }) => {
          CookiesService.setItem('token', token)

          commit('setToken', token)
          return dispatch('getUserFromToken')
        })
    },

    logout ({ commit }) {
      CookiesService.remove('token')
      commit('setToken', null)
      commit('userData', null)
    },

    requestResetPassword ({ commit }, data) {
      return AuthService.requestResetPassword(data)
    },

    resetPassword ({ commit }, data) {
      return AuthService.resetPassword(data)
    }
  }
}
