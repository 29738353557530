let timeout = null

export default {
  state: {
    message: '',
    type: 'success',
    visible: false
  },

  mutations: {

    showNotification (state, data) {
      if (timeout !== null) {
        clearTimeout(timeout)
      }

      const { type, message } = data

      state.message = message
      state.type = type
      state.visible = true

      timeout = setTimeout(() => {
        this.commit('hideNotification')
      }, 5000)
    },

    hideNotification (state) {
      state.message = null
      state.type = null
      state.visible = false
    }
  },

  actions: {
    showNotification ({ commit }, data) {
      commit('showNotification', data)
    },

    hideNotification ({ commit }) {
      commit('hideNotification')
    }
  }
}
