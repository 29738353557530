import AuthRoutes from './auth.routes'
import AppRoutes from './app.routes'
import UserRoutes from './user.routes'

export const routes = [
  {
    path: '*', redirect: '/auth/login'
  },

  {
    path: '/',
    component: () => import(/* webpackChunkName "default-layout" */ '@/layouts/DefaultLayout'),
    children: AppRoutes
  },

  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName "auth-layout" */ '@/layouts/AuthLayout'),
    children: AuthRoutes
  },

  {
    path: '/admin-panel',
    name: 'AdminPanel',
    component: () => import(/* webpackChunkName "default-layout" */ '@/layouts/DefaultLayout'),
    children: UserRoutes
  },

  {
    path: '/playground',
    name: 'Playground',
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "playground" */ '@/views/Playground.vue')
  }
]
