<template>
  <div id="app" class="flex flex-col h-full">
    <router-view />

    <el-loading v-if="loading" :loading="loading" />

    <el-notification v-if="visible" />
    <transition name="slide-fade">
      <el-slide-tooltip v-if="slideVisible && !isAuthLayout" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ElNotification = () => import('@/components/shared/ELNotification')
const ElLoading = () => import('@/components/shared/ELLoading')
const ElSlideTooltip = () => import('@/components/shared/ELSlideTooltip')

export default {
  components: { ElNotification, ElLoading, ElSlideTooltip },

  computed: {
    ...mapState({
      visible: state => state.notification.visible,
      loading: state => state.loading.loading,
      slideVisible: state => state.slideTooltip.visible
    }),

    isAuthLayout () {
      return this.$route.matched.some(route => route.name === 'Auth')
    }
  }
}
</script>
